// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { AreYouAHostBox } from 'styleguide/components';
import type { ExampleListing } from 'app/types';
import type { NavbarProps, Collection } from 'layout/types';

import MoreVacationIdeas from 'rescript/styleguide/components/MoreVacationIdeas/MoreVacationIdeas.bs.js';
import TripListsCollections from 'rescript/styleguide/components/TripListCollections/TripListsCollections__JsBridge.bs.js';
import Hero from './Hero/Hero';
import css from './WelcomePage.scss';
import TrustBanner from './TrustBanner/TrustBanner';
import HicheeDifferent from './HicheeDifferent/HicheeDifferent';
import WelcomePageStructuredData from './WelcomePageStructuredData/WelcomePageStructuredData';

const PAGE_SEO_DATA = {
  headingContent: 'Don’t overpay. Check that\nAirbnb before you book it!',
  pageTitle: 'HiChee | Check and Compare Airbnb, Booking, and Vrbo Vacation Rental Prices',
  previewTitle: 'HiChee | Don’t overpay, check that Airbnb before you book it!',
  keyword: '',
  description:
    'Search around 14 million vacation rental deals in just one click! Just paste the link ' +
    'from Airbnb / Vrbo / Booking and see all prices, including from the direct host.',
};

type Props = {
  contextualListingsPath: string,
  desktop: boolean,
  tablet: boolean,
  mobile: boolean,
  chrome: boolean,
  isUserLoggedIn: boolean,
  openSignUpModal: () => void,
  sendMagicLink: (data: { email: string }, callback: { onSuccess?: Function, onFailure?: Function }) => void,
  openInvalidTokenModal: () => void,
  slug: string | null,
  exampleListing: ExampleListing,
  hasFocusedSearchBar: boolean,
  onFocusSearchBar: (focusedOnSearchByCity: boolean) => void,
  showSearchByCity: boolean,
  onToggleSearchBar: () => void,
  fansVideos: Array<string>,
  navbar: NavbarProps,
  collections: Array<Collection>,
};

const WelcomePage = (props: Props) => {
  const { contextualListingsPath, desktop, tablet, mobile, exampleListing, fansVideos, collections } = props;

  return (
    <main className={css.WelcomePage}>
      <Helmet preserved>
        <title>{PAGE_SEO_DATA.pageTitle}</title>
        <meta name="keywords" content={PAGE_SEO_DATA.keyword} />
        <meta name="description" content={PAGE_SEO_DATA.description} />
        <meta property="og:title" content={PAGE_SEO_DATA.previewTitle} />
        <meta property="twitter:title" content={PAGE_SEO_DATA.previewTitle} />
      </Helmet>
      <WelcomePageStructuredData />

      <Hero
        contextualListingsPath={contextualListingsPath}
        desktop={desktop}
        tablet={tablet}
        mobile={mobile}
        titleContent={PAGE_SEO_DATA.headingContent}
        exampleListing={exampleListing}
        hasFocusedSearchBar={props.hasFocusedSearchBar}
        onFocusSearchBar={props.onFocusSearchBar}
        toggleSearchBar={props.onToggleSearchBar}
        showSearchByCity={props.showSearchByCity}
      />
      <TrustBanner />
      <HicheeDifferent desktop={desktop} mobile={mobile} fansVideos={fansVideos} />
      <AreYouAHostBox />
      <TripListsCollections collections={collections} />
      <MoreVacationIdeas />
    </main>
  );
};

export default WelcomePage;
